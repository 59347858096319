import React from "react"
import Article from "../components/Article"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"
import ImageGrid from "../components/ImageGrid"
import ImageWithTitleFixed from "../components/ImageWithTitleFixed"
import SinglePortraitImageWithTitle from "../components/SinglePortraitImageWithTitle"

function burdock({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.burdock.coverCap")}}
                  title={t("items.burdock.title")}>
    <Paragraph>{t("items.burdock.p1")}</Paragraph>
    <SinglePortraitImageWithTitle fluid={data.burdock1.childImageSharp.fluid}
                                  title={t("items.burdock.cap1")} />
    <ImageWithTitle fluid={data.burdock2.childImageSharp.fluid} title={t("items.burdock.cap2")}/>
    <Paragraph>{t("items.burdock.p2")}</Paragraph>
    <ImageWithTitleFixed fixed={data.burdock3LargeSquare.childImageSharp.fixed}
                    title={t("items.burdock.cap3")}/>
    <Paragraph>{t("items.burdock.p3")}</Paragraph>
    <ImageGrid style={{ gridTemplateColumns: 'auto 330px' }}>
      <ImageWithTitleFixed fixed={data.grid1_1.childImageSharp.fixed} title={t("items.burdock.cap4")}/>
      <ImageWithTitleFixed fixed={data.grid1_2.childImageSharp.fixed} title={t("items.burdock.cap5")}/>
    </ImageGrid>
    <ImageWithTitle fluid={data.burdock4.childImageSharp.fluid}
                    title={t("items.burdock.cap6")}
                    style={{ padding: '0 10%' }}/>
    <ImageGrid style={{gridTemplateColumns: 'auto auto'}}>
      <ImageWithTitleFixed fixed={data.grid2_1.childImageSharp.fixed} title={t("items.burdock.cap7")}/>
      <ImageWithTitleFixed fixed={data.grid2_2.childImageSharp.fixed} title={t("items.burdock.cap8")}/>
    </ImageGrid>
  </Article>
}

export default i18nHOC(burdock);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "burdock/IMG_7533.jpg" }) {
        ...CoverImage
    },
    burdock1: file(relativePath: { eq: "burdock/13254792_1191494764196543_4452136720265069238_o.jpg" }) {
      ...FluidArticleImage
    },
    burdock2: file(relativePath: { eq: "burdock/DSC_2476 (1).JPG" }) {
      ...FluidArticleImage
    },
    burdock3LargeSquare: file(relativePath: { eq: "burdock/DSC05496.jpg" }) {
      childImageSharp {
        fixed(height: 750, width: 750, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    grid1_1: file(relativePath: { eq: "burdock/DSC_2548.JPG" }) {
      childImageSharp {
        fixed(height: 330) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    grid1_2: file(relativePath: { eq: "burdock/DSC05475.jpg" }) {
      childImageSharp {
        fixed(height: 330, width: 330, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    burdock4: file(relativePath: { eq: "burdock/DSC_1637 (2).JPG" }) {
      ...FluidArticleImage
    },
    grid2_1: file(relativePath: { eq: "burdock/purslane on paliashvili .JPG" }) {
      childImageSharp {
        fixed(width: 415) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    grid2_2: file(relativePath: { eq: "burdock/DSC_9702.JPG" }) {
      childImageSharp {
        fixed(width: 415) {
          ...GatsbyImageSharpFixed
        }
      }
    },
  }
`